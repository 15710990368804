import { Player } from '@lottiefiles/react-lottie-player';
import styled from 'styled-components';

const SetupResourcesPlayer = () => {
    return (
        <PlayerWrapper>
            <Player
                src={'https://res.cloudinary.com/honeybook/raw/upload/v1673793171/finance/lotties/FinTech_Loader_v007.json'}
                style={{ width: '140px', height: '140px' }}
                autoplay
                loop
            />
        </PlayerWrapper>
    );
};

const PlayerWrapper = styled.div`
    display: flex;
    height: 100vh;
    padding-block-start: 140px;
`;

export default SetupResourcesPlayer;
